
// mock an array 2 objects with name, occupation, bio, video url

let runoffCandidates = [
  {
    firstname: "Brandon",
    lastname: "Johnson",
    ballotorder: 1,
    "ward#": "",
    "incumbent?": "FALSE",
    "pd#": "",
    occupation: "Cook County commissioner",
    dob: "03/28/1976",
    civic: "",
    candidatevideo: "https://youtu.be/_dVyIRl4Q0o",
    website: "",
    fb: "https://www.facebook.com/Brandon4Chicago",
    twitter: "@Brandon4Chicago",
    ig: "https://www.instagram.com/brandon4chicago/",
    tiktok: "",
    youtube: "",
    linkedin: "",
    statement: "",
  },
  {
    firstname: "Paul",
    lastname: "Vallas",
    ballotorder: 2,
    "ward#": "",
    "incumbent?": "FALSE",
    "pd#": "",
    occupation: "Former city budget director",
    dob: "06/10/1953",
    civic: "",
    candidatevideo: "https://youtu.be/SZygZC79XhM",
    website: "",
    fb: "https://www.facebook.com/PaulGVallas",
    twitter: "@PaulVallas",
    ig: "https://www.instagram.com/paulgvallas/",
    tiktok: "",
    youtube: "",
    linkedin: "https://www.linkedin.com/in/paul-vallas-b2564816a/",
    statement: "",
  },
];

runoffCandidates = runoffCandidates.map((c) => {
  c.dob = new Date(c.dob);
  const today = new Date();
  let birthDate = today.getFullYear() - c.dob.getFullYear();
  const m = today.getMonth() - c.dob.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < c.dob.getDate())) {
    birthDate--;
  }
  const age = birthDate;

  c.dob = `${age} years old`;
  if (c.candidatevideo) {
    c.candidatevideo = c.candidatevideo.replace(
      "youtu.be",
      "www.youtube.com/embed"
    );
    c.candidatevideo = c.candidatevideo + "?modestbranding=1&rel=0";
  }
  return c;
});
